.contact-section{
   
    flex-direction: column;
}

.contact-links{
    display: flex;
    flex-direction: column;
}

.contact-heading{
    font-size: 36px;
}

.contact-link{
    display: flex;
    align-items: center;
}

/* Style all font awesome icons */
.fa-custom {
    padding: 10px;
    font-size: 40px;
    width: 50px;
    text-align: center;
    text-decoration: none;
}

/* Add a hover effect if you want */
.fa-custom:hover {
    opacity: 0.7;
}

/* .fa-custom:visited {
    color: white;
} */
