.card {
  min-width: 200px;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(233, 231, 245);
  flex: 0 1 auto;
  height: 100%;
  margin: 0px 10px;
}

.card-image {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.card-text-content p {
  font-size: 1rem;
  margin: 5px;
}

.card-text-content h2 {
  text-align: center;
  font-size: 1.5rem;
  margin: 5px;
}

.card-button-group {
  display: flex;
  justify-content: center;
}


@media (max-width: 650px) {
  .card {
    flex-basis: calc(33.3333% - 10px);
  }
}

@media (max-width: 450px) {
  .card {
    flex-basis: calc(50% - 10px);
  }
}