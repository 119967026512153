/* CSS animations background part */
.animated-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
    .bg-obj {
        position: absolute;
        width: 2px;
        height: 30px;
        background: linear-gradient(to bottom, #8e44ad, #ec87c0);
        opacity: 0.8;
        animation: floatAnimation 6s infinite linear;
    }
}



@keyframes floatAnimation {
    0% {
        transform: translate(0, 0) rotate(0);
    }

    25% {
        transform: translate(25%, -25%) rotate(90deg);
    }

    50% {
        transform: translate(50%, 0) rotate(180deg);
    }

    75% {
        transform: translate(25%, 25%) rotate(270deg);
    }

    100% {
        transform: translate(0, 0) rotate(360deg);
    }
}

/* End of CSS animations */