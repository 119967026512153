.btn-link{
  margin: 5px;
}
.button {
  display: inline-block;
  border-radius: 4px;
  background-color: #25033b;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  width: fit-content;
  transition: all 0.5s;
  cursor: pointer;
}

.outline {
  border: 2px solid white;
}

.rounded {
  border-radius: 50%;
  width: 100%;
}

.fullSize {
  width: 100%;
}

.button span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button:hover {
  opacity: 0.5;
}