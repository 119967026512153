.projects-section {
    /* height: fit-content !important; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}

.carousel-container{
    padding-bottom: 20px;
    margin-top: 60px;
}

.react-multi-carousel-dot-list{
    position: relative !important;
    margin-top: 20px;
}

.explore-more-btn{
    padding-top: 20px;
}

.heading-projects{
    font-size: 32px;
}