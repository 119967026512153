.header-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .header-box {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        flex-direction: row;
    }
    
}

.header-text {
    max-width: 100%;
    text-align: center;
}

.colorful-character {
    font-size: 24px; /* Example size */
    background: linear-gradient(45deg, #ff6b6b, #f06595); /* Gradient background */
    -webkit-background-clip: text; /* Clip the background to the text */
    -webkit-text-fill-color: transparent; /* Make the text fill color transparent */
}

@media screen and (min-width: 800px) {
    .header-box {
        flex-direction: column;
    }
}

#description-me {
    margin-bottom: 0;
}

#changing-message {
    margin-top: 0;
}

.main-heading{
    font-size: 36px;
}

.changing-text{
    min-height: 60px;
    font-size: 32px;
}

/* Style all font awesome icons */
.fa-brands {
    padding: 10px;
    font-size: 40px;
    width: 50px;
    text-align: center;
    text-decoration: none;
}

/* Add a hover effect if you want */
.fa-brands:hover {
    opacity: 0.7;
}


.scroll-down-container{
    padding-top: 40px;
}


