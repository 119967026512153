.content {
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow: auto;
  background-color: white;
  scroll-behavior: smooth;
  color: black;
  padding: 0px;
  margin: 0px;
}

/*  background-color: #25033b; */

.content section {
  height: 100vh;
  border: 1px solid black;
  scroll-snap-align: start;
}